<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoProductCat",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoProductCat",
        hero: {},
        status: {},
        data:[],
        metatitleLength: 0,
        maxmetatitleLength: 60,
        metadescLength: 0,
        maxmetadescLength: 160,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, { data: this.data.data, type: 'sort' }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
    },
    watch: {
      '$route.query'(v) {
        if(this.$route.params.id){
          this.$set(this.row, 'status', this)
          this.$set(this.row, 'search', this)
          this.$set(this.row, 'page', this)
          setTimeout(()=>{
            this.row.search = this.$route.query.search
            this.row.page = this.$route.query.page
          },500)
        }
        this.refreshData()
      },
      'filter.search'(v){
        this.search()
      },
      'row.apc_seo_title'(v){
        this.metatitleLength = v.length
      },
      'row.apc_seo_desc'(v){
        this.metadescLength = v.length
      },
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
      <div class="card" v-if="!$route.params.id">
        <div class="card-header">
          <VForm @resp="search">
            <div class="row gutter-10">
              <div class="col-sm-4">
                <h5 class="card-title mb-0">{{ObjectName}} List</h5>
              </div>
              <div class="col-9 col-lg-3 col-sm-4 ml-auto">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                      placeholder="Search...">
                    <div class="input-group-append">
                      <button class="btn btn-info" type="button">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 col-sm-auto">
                <router-link :to="{name:Name}" class="btn btn-block btn-warning">Reset</router-link>
              </div>
            </div>
          </VForm>
        </div>
        <div class="card-body">
          <div class="row" v-if="!$route.params.id">
            <div class="col-12">
              <draggable v-model="data.data" tag="div" class="row gutter-20" @end="endDrag" v-if="data.data.length">
                <div class="col-lg-3 col-md-4 col-lg-20" v-for="(v,k) in data.data" :key="k">
                  <div class="item-list collection">
                    <div class="row text-center">
                      <div class="col-md-12">
                        <div class="product-img recipe-cat mb-2">
                          <img :src="uploader(v.apc_img_logo,'250')" class="mw-100" alt="prod">
                          <div class="pro-img-overlay">
                            <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}" v-if="page.moduleRole('Edit')" v-tooltip="'Edit'"><i
                                class="ti-marker-alt"></i></router-link>
                            <a href="javascript:;" v-if="v.apc_id > 1 && page.moduleRole('Delete')" class="bg-danger" @click="deleteItem($event,k)" v-tooltip="'Delete'"><i class="ti-trash"></i></a>
                          </div>
                          <span class="label label-info" v-if="v.apc_is_active=='Y'">Active</span>
                          <span class="label label-danger" v-else="v.apc_is_active=='N'">Inactive</span>
                        </div>
                        <h5 class="card-title mt-1">{{v.apc_name}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <div v-if="NotFound" class="text-center col-md-12">
                <h5 class="tc">{{NotFound}}</h5>
              </div>
              <div v-if="data.data===false" class="text-center col-md-12">
                <LoadingSpinner light></LoadingSpinner>
              </div>
            </div>
          </div>
          <Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
        </div>
      </div>

      <div class="card" v-if="$route.params.id">
        <VForm @resp="submitForm" method="post">
          <div class="card-header">
            <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
          </div>
          <div class="card-body">
            <div class="info"></div>
            <div class="row">
              <div class="col-sm-3">
                <BoField :name="'apc_img_logo'" :label="'Image Logo'">
                  <Uploader name="apc_img_logo" type="logo" uploadType="cropping" :param="{thumbnail:true}" v-model="row.apc_img_logo"></Uploader>
                </BoField>
              </div>
              <div class="col-sm-3">
                <BoField name="apc_img_tagline">
                  <Uploader name="apc_img_tagline" type="tagline" uploadType="cropping" :param="{thumbnail:true}" v-model="row.apc_img_tagline"></Uploader>
                </BoField>
              </div>
              <div class="col-sm-6">
                <BoField name="apc_img_background">
                  <Uploader name="apc_img_background" v-if="row.id == 1" type="background2" uploadType="cropping" :param="{thumbnail:true}" v-model="row.apc_img_background"></Uploader>
                  <Uploader name="apc_img_background" v-else type="background" uploadType="cropping" :param="{thumbnail:true}" v-model="row.apc_img_background"></Uploader>
                </BoField>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <BoField name="apc_name" v-model="row.apc_name"></BoField> 
                <BoField name="apc_link" :attr="{type:'url',placeholder:'https://www.hilo.co.id'}" v-model="row.apc_link"></BoField> 
              </div>
              <div class="col-sm-4">
                <BoField name="apc_is_active">
                  <radio name="apc_is_active" v-model="row.apc_is_active" option="Y" :attr="validation('apc_is_active')">Active</radio>
                  <radio name="apc_is_active" v-model="row.apc_is_active" option="N">Inactive</radio>
                </BoField>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <h5 class="card-title pb-3 pt-2">Meta Content</h5>
              </div>
              <div class="col-sm-6">
                <BoField class="mb-0" name="apc_seo_title" :attr="{minlength:'3'}" v-model="row.apc_seo_title" mandatory></BoField>
                <small class="mb-2 d-inline-block text-info">{{maxmetatitleLength-metatitleLength}} character remaining </small> 
                <BoField name="apc_seo_keyword" mandatory>
                  <TagsInput name="apc_seo_keyword" v-model="row.apc_seo_keyword" :placeholder="'Add tag then enter'"
                  :attr="validation('apc_seo_keyword')"></TagsInput>
                </BoField>
              </div>
              <div class="col-sm-6">
                <BoField class="mb-0" name="apc_seo_desc" mandatory>
                  <textarea name="apc_seo_desc" rows="5"  :attr="{minlength:'10'}" v-model="row.apc_seo_desc" v-bind="validation('apc_seo_desc')"
                  class="form-control"></textarea>
                </BoField>
                <small class="mb-2 d-inline-block text-info">{{maxmetadescLength-metadescLength}} character remaining </small> 
              </div>
            </div>
            <div class="text-right">
              <button type="submit" class="fcbtn btn btn-info btn-rounded btn-1e btn-loading">{{(row.id?"Update":"Add")+" "+ObjectName}} 
                <i class="icon-arrow-right14 position-right"></i>
              </button>
            </div>
          </div>
        </VForm>
      </div>
  </div>
</template>